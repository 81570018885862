import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Enviroment } from '../../enviroment';

@Injectable()
export class PotrillosService {

  private headers: HttpHeaders = null;

  constructor(public http: HttpClient) { }

  getAll(): Observable<any> {
    this.headers = new HttpHeaders({
      'Content-Type':  'application/json'
    })
    return this.http.get(Enviroment.getApiUrl('potrillos'), {headers: this.headers, responseType: 'json'});
  }

}

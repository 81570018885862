import { Injectable } from '@angular/core';

@Injectable()
export class TextService {

  constructor() { }

  getHistoriaText() {
    return [
      {
        title: 'LOS INICIOS',
        parrafos: [
        `Dentro de este catálogo van a encontrar la información de los pedigrees de 39
        potrillos, pero mucho más importante que estos datos, este catálogo es el punto de
        inflexión en la trayectoria de un proyecto, es el pasado y el futuro, es el destino y el
        kilómetro cero, es donde queríamos llegar para poder empezar.`,
        `Es el resultado de un camino largo, empedrado, pantanoso y casi siempre en repecho,
        pero lo recorrimos con la convicción de que era el correcto, que era la ruta para llegar
        hasta aquí, y golpearle la puerta al guionista de nuestro destino para decirle: estamos
        listos, empezá a escribir.`
        ]
      },
      {
        title: 'PADRILLOS',
        parrafos: [
          `Hace quince años atrás, nuestro país hípico se estaba desperezando, y volvía a abrir los
          ojos, después de un sueño que lo marginó de la escena internacional por completo. En
          esos años, empezamos a masticar una idea, casi un sueño, de poder reunir amigos
          criadores alineando esfuerzos que se expresaran en la incorporación de padrillos de
          calidad.`,
          `Comenzamos con las iniciativas del Haras Flanqueadores, María Isabel y Viejo Molino,
          centralizados en Haras Rapetti, y fue así como formamos un sindicato para traer un
          padrillo que ya tuviera una buena trayectoria como tal; Hardy.
          Y el destino nos pegó la primera cachetada, Hardy fracasó como padrillo en nuestro
          país.`,
          `El primer paso para levantarse de un golpe como aquel, fue asumirlo, reconocer que
          no fue lo que esperábamos. Porque de eso se trata este camino.
          El reto es reflexionar, corregir el timón y seguir adelante. Y seguimos…`
        ]
      },
      {
        title: 'VIENTRES',
        parrafos: [
          `Al tiempo que consolidábamos la sociedad de los padrillos, también comenzamos a
          buscar oportunidades en el exterior para incorporar vientres.`,
          `Somos conscientes que la sustentabilidad en el tiempo de nuestros planes de cría es en
          base a las buenas yeguas madre, que luego tendrán productos que llevarán la grifa del
          padrillo que sea. Pero cualquiera sea el padrillo, tiene que encontrar en una familia
          sólida, el terreno fértil que permita desarrollar su potencial, de lo contrario el
          resultado será tan efímero como incipiente`,
          `La crisis norteamericana nos permitió importar madres con genética de primer mundo.
          Mas allá de la fuerte depresión de los valores en los Estados Unidos, nuestro mercado
          uruguayo siempre ha condicionado la magnitud de las inversiones, a pesar de lo cual,
          muchas veces (guiados por la pasión) nos sentamos en la mesa principal, y aunque no
          llegamos a probar la crema, nunca nos interesó tampoco raspar el fondo del tarro.`
        ]
      },
      {
        title: 'NUEVAS FUERZAS',
        parrafos: [
          `Después se sumó al proyecto otro de los pilares, el Haras Estrella del Sur, y con la
          convicción de que el Uruguay necesitaba un hijo de Danehill, incorporamos uno que
          además había ganado varios Grupo 1: Artiste Royal. `,
          `Sirvió una temporada y quedó estéril, lo cual fue un nuevo golpe a nuestro entusiasmo,
          pero nos volvimos a levantar, y en la misma línea trajimos a Alexander Of Hales, otro
          hijo de Danehill. `,
          `Tras esos tropiezos, aprendimos algo importante con respecto a los padrillos europeos.
          Es unánime la opinión de que a todos nos gustan mucho mas las carreras largas que las
          cortas, pero la ansiedad lógica de los propietarios y el modelo hípico de nuestro país,
          son contrarios a ese deseo. Porque como regla general, los caballos que tienen fondo
          carecen de precocidad, entonces terminamos sometiendo a esfuerzos de velocidad a
          muy temprana edad, a caballos nacidos para lo contrario.`
        ]
      },
      {
        title: 'COMERCIALIZACION (IMPERDIBLE)',
        parrafos: [
          `Paralelo a nuestro permanente esfuerzo de mejorar nuestros reproductores (padrillos
            y yeguas), empezamos a transitar un camino propio de comercialización.`,
          `Este concepto comercial estuvo siempre (y lo está) sostenido en dos pilares
          fundamentales: transparencia en la operativa y calidad de la oferta. Este evento de
          comercialización lo llamamos IMPERDIBLE, y persigue el objetivo de jerarquizar la
          venta de los animales, con especial foco en la atención a nuestros clientes.`,
          `Los IMPERDIBLE, son remates que pretenden que nuestros clientes encuentren
          calidad, transparencia y además disfruten del evento.`
        ]
      },
      {
        title: 'FOMENTO DE LA HIPICA',
        parrafos: [
          `En los primeros años luego del renacimiento de nuestro turf, muchas de las carreras
          importantes en Maroñas, eran ganadas por caballos extranjeros de discreta jerarquía,
          pero que sobresalían frente a la devastación sufrida por los planteles nacionales
          merced a la ausencia de carreras oficiales.`,
          `Era una época en que la actividad hípica estaba recién dada de alta, luego de muchos
          años de estar en coma. Pero después la Dirección de Casinos del Estado decidió
          fomentar la actividad, y de la mano con HRU dieron un histórico impulso a la misma,
          que se desarrolló a una velocidad mayor que cualquier otra hípica del mundo.`,
          `Entonces vinieron los aumentos de premios, el bonus a los caballos nacionales, Las
          Piedras, El SINT, los incentivos a los criadores, el Tomo 1, etc.`,
          `Y casi sin darnos cuenta pasamos de estar agonizando, a retozar. Y somos ejemplo y
          envidia de la región en materia de gestión y organización hípica. Pero ya hace varios
          años que el nivel competitivo de Maroñas es otro, el súbito crecimiento de nuestra
          hípica presionó hacia el aumento en cantidad y calidad del parque caballar.`,
           `También se incrementó notoriamente el flujo de caballos desde la región, sobre todo
           desde Brasil, pero ya no de aquellos de discreto origen que llegaban otrora, sino de
           animales de notoria calidad atraídos por la realidad de nuestro modelo hípico.`,
           `En esos años en que nuestra hípica despegó, se desnudó otro de nuestros errores.
           Aquellas yeguas que habíamos incorporado desde el exterior (fundamentalmente
           desde Estado Unidos) habían llegado tarde.`,
           `La tasa de crecimiento de la hípica en Uruguay había sido mucho mas rápida que el
           tiempo biológico que lleva la respuesta de un evento de selección de un vientre. Dicho
           de otra manera, cuando seleccionamos esas yeguas eran notoriamente mejoradoras
           para el medio nacional, pero cuando sus hijos empezaron a correr (tres o cuatro años
           mas tarde) el nivel de las carreras en Maroñas había cambiado tanto (para mejor) que
           no alcanzaba.`,
           `Claro que fue otro golpe en nuestro camino. Pero lejos de socavar nuestro empeño,
           cuando advertimos esta realidad, nos alegramos. Bienvenida sea esta saludable
           actualidad de nuestra actividad, aunque tengamos que replantear el alcance de
           nuestras inversiones.`
        ]
      },
      {
        title: 'MAYORES EXIGENCIAS – NUEVOS PADRILLOS',
        parrafos: [
          `En base a ese replanteo decidimos seguir trabajando, pero ya con otras exigencias a la
          hora de seleccionar. Y se sumó el Haras Los Méndez al proyecto, otra de nuestras
          piezas fundamentales.`,
          `Fuimos en busca de padrillos consagrados, y con enorme esfuerzo incorporamos a
          Honour And Glory, una celebridad de veinte años. Algunas voces nos decían que era
          viejo, como si el ADN se gastara con el tiempo, o como si él mismo tuviera que
          alimentar a los hijos del centenar de yeguas que preñó el primer año. Pero conscientes
          de su edad, nuestro objetivo era generar un número de hembras (hijas de él) que
          fueran vientres seguros para nuevos padrillos en el futuro. Y si la inversión duraba un
          año, mala suerte, tomamos el riesgo. Pero con la grandeza que sólo ostentan unos
          poquitos, lo volvió a hacer. Volvió a ganar la Estadística de Primera Generación, por
          tercera vez, en su tercer hogar. Y por suerte el protagonista de esta serie esta mejor
          que nunca, y este año va por su quinta temporada.`,
          `Un par de años más tarde, y como hacemos constantemente, analizamos el Stallion
          Book norteamericano para encontrar un padrillo como Posse, que tiene las
          características que perseguíamos. Primero vino en shuttle dos años y luego el destino y
          nuestro esfuerzo hicieron que se quedara con nosotros definitivamente.`,
          `Dentro de esa permanente obsesión por asumir desafíos a largo plazo como parte de
          nuestro camino; mientras las máquinas estaban levantando la vieja pista de vareo de
          Maroñas, nosotros estábamos comprando el primer hijo de Galileo que llegaría a
          nuestro país. Hace pocas semanas, un sol de marzo iluminó la nueva pista de césped
          de nuestro hipódromo, mientras los hijos de Midas Touch están por cumplir el año.`
        ]
      },
      {
        title: 'NUEVOS VIENTRES',
        parrafos: [
          `Decisiones parecidas tomamos con yeguas madre, disminuyendo el caudal y
          aumentando la presión al momento de seleccionar. Así fue como fueron llegando en
          todos estos años las Grupo 1, las productoras clásicas, las Tapit, las Unbridled´s Song,
          las Pulpit, las Tiznow; las campeonas o sus madres, etc. Buscamos afuera y pusimos a
          prueba acá. Aprobamos y reprobamos`,
          `Hoy, sin tentarnos a caer en la comodidad estática de un concepto dinámico por
          definición, podemos decir que tenemos el plantel de yeguas que buscábamos.
          Nuestro otro tema de desvelo siempre ha sido que el potencial genético que
          construimos, pueda ser expresado por nuestros productos en toda su magnitud. Para
          lo cual comenzamos a trabajar hace algunos años con un equipo de nutricionistas
          especializados en caballos de carrera, que nos han guiado hacia la situación que
          siempre anhelábamos en este aspecto. `
        ]
      },
      {
        title: '2018',
        parrafos: [
          `Nuestro proyecto en la actualidad esta conformado por los Haras Estrella del Sur, Los
          Mendez, y Flanqueadores, con participación también de Haras Del Rocío y El Surco.
          Este año también se ha sumado el reconocido Haras Orilla del Monte (ARG).
          Hoy en 2018, la hípica uruguaya se encuentra en una situación histórica, con un
          modelo piramidal de hipódromos que distribuye oportunidades, con un hipódromo
          principal con infraestructura de primer mundo, con la mejor relación premio-pensión
          de América, etc. En este contexto de actualidad consideramos que la vidriera que es en
          materia hípica nuestro país hacia la región nos otorga una oportunidad que no
          tenemos pensado desperdiciar.`,
          `Porque nos preparamos para esto.`,
          `Porque trabajamos mucho para esto.`,
          `Porque nos golpeamos, pero aprendimos.`,
          `Porque nos entró agua en el barco, pero atravesamos la tormenta.`,
          `Porque siempre tuvimos un rumbo, y lo corregimos varias veces, pero la disciplina de
          ajustarnos al plan nunca la olvidamos.`,
          `Por eso estos párrafos pretenden, a través de la narración del desarrollo de nuestra
          historia, nuestro camino, explicar cómo y por qué llegamos a este remate.`,
          `Por qué es la primera vez que vamos a rematar casi cuarenta potrillos. Y la respuesta
          es tan simple, como sacrificado fue poder llegar a ella:`,
          `Hoy sentimos alegría de poder entregarle este catálogo a nuestros clientes, donde
          aparecen pedigrees conocidos y reconocidos internacionalmente; y seguimos
          caminando…hacia nuestros objetivos definidos y en la ratificación de lo conseguido.`,
        ]
      }
    ]
  }

  getPreofertaText() {
    return [
      {
        title: 'PREOFERTA',
        parrafos: [
          `Con la preoferta, quienes participen de la misma, ganan cupones para un sorteo (por un gran premio) sin obligación de compra.`,
          `La preoferta comenzará unos días antes el remate (será anunciado) y la operativa será dentro de un grupo de whatsapp.`,
          `Por cada pique en la preoferta, quien lo realice, gana un cupón.`,
          `Quienes compren en el remate (sin haber preofertado por el caballo
que compran) ganan 5 cupones`,
          `Quienes compren en el remate y además habían preofertado por el
caballo que compran, obtienen 10 cupones, mas un cupón por cada
pique hecho en la preoferta.`,
          `El mínimo de la preoferta es U$ 500 de cuota y se pone de U$ 100
en U$ 100.`,
          `El sorteo se ralizará al final del remate y para recibir el premio el
ganador debe estar presente. En caso de que la persona ganadora
no esté presente el sorteo se volverá a realizar.`,
          `En el remate los animales se comenzarán vendiendo a partir del
pique mas alto de la preoferta, se dará a conocer quién lo hizo, y si
esta oferta no es superada el caballo se adjudica a dicha persona en
ese valor. Dado que las preofertas tienen valor de posible compra,
quienes participen deben tener el crédito aprobado por la firma
Zambrano y Cia`,
        ]
      }
    ]
  }

}

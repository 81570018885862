import { Component, OnInit } from '@angular/core';
import { TextService } from '../../services/text/text.service';
import { ReservarService } from '../../services/reservar/reservar.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  modalHistoria: any;
  preofertaText: any;
  formReserva: any = {nombre: '', apellido: '', telefono: '', correo: ''};
  
  constructor(private textService: TextService, private reservarService: ReservarService) { }

  ngOnInit() {
    this.modalHistoria = this.textService.getHistoriaText();
    this.preofertaText = this.textService.getPreofertaText();
  }

  reservar() {
    if(this.formReserva.nombre == '') {
      return false;
    }
    if(this.formReserva.apellido == '') {
      return false;
    }
    if(this.formReserva.telefono == '') {
      return false;
    }
    if(this.formReserva.correo == '') {
      return false;
    }

    this.reservarService.reservar(this.formReserva).subscribe((response) => {
      if(response.msg == 'success') {
        alert(response.data);
        this.formReserva.nombre = '';
        this.formReserva.apellido = '';
        this.formReserva.telefono = '';
        this.formReserva.correo = '';
      }
    }, (error) => {
      alert(error.data);
    });
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { IPotrillos } from '../../models/potrillos.model';
import { PotrillosService } from '../../services/potrillos/potrillos.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-potrillos',
  templateUrl: './potrillos.component.html',
  styleUrls: ['./potrillos.component.css']
})
export class PotrillosComponent implements OnInit {

  potrillo: IPotrillos;
  galeria: string[];
  pagesGaleria: number[];
  image: string;
  videos: string[];
  auxDate: string;

  constructor(
    private route: ActivatedRoute,
    private potrillosService: PotrillosService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private sanitizer: DomSanitizer
  ) { 

  }

  ngOnInit() {
    window.scroll(0,0);
    this.potrillo = JSON.parse(this.storage.get('potrillo'));
    console.log(this.potrillo);
    this.auxDate = this.potrillo.fecha_nac.replace(/-/g, "/");
    if(this.potrillo.galeria != null) {
      this.galeria = JSON.parse(this.potrillo.galeria);
      this.pagesGaleria = Array.from(Array(Math.ceil(this.galeria.length / 3)).keys());
    }

    if(this.potrillo.video != null) {
      this.videos = JSON.parse(this.potrillo.video);
    }

  }

  getSexo() {
    if(this.potrillo.sexo == 'macho'){
      return './assets/Img/icon-sex-m.jpeg';
    }
    return  './assets/Img/icon-sex-f.png';
  }

  getUrlPlanNutricional(option: number) {
    if(this.potrillo.plan_nutricional != null) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(this.potrillo.plan_nutricional);
    }
  }

  getFecha() {
    return '30-04-1988';
  }
  showPlan() {
    if(this.potrillo.plan_nutricional != null) {
      return true;
    }
    return false;
  }

  getUrlPedigree(option: number) {
    if(this.potrillo.pedigree != null) {
      window.open(this.potrillo.pedigree, "_blank");
    }
  }

  showPedigree() {
    if(this.potrillo.pedigree != null) {
      return true;
    }
    return false;
  }

  getUrlGrafica(option: number) {
    if(this.potrillo.grafica != null) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(this.potrillo.grafica);
    }
  }

  showGrafica() {
    if(this.potrillo.grafica != null) {
      return true;
    }
    return false;
  }

  getUrlVideo() {
    if((this.videos != null) && (this.videos[0] != null) && (this.videos[0] != undefined)) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${this.videos[0]}?rel=0&amp;showinfo=0&amp;modestbranding=1&amp;wmode=transparent`);
    }
    //return this.sanitizer.bypassSecurityTrustResourceUrl("http://www.youtube.com/embed/xKaD76wdMNU?rel=0&amp;showinfo=0&amp;modestbranding=1&amp;wmode=transparent");

  }

  getUrlHeader() : any {
    console.log(`https://www.youtube.com/embed/${this.potrillo.header_url}?rel=0&amp;showinfo=0&amp;modestbranding=1&amp;wmode=transparent`);
    return this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${this.potrillo.header_url}?rel=0&amp;showinfo=0&amp;modestbranding=1&amp;wmode=transparent`);
  }

  setImgModal(i: string) {
    this.image = i;
  }

}

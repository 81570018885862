import { Component, OnInit, Inject } from '@angular/core';
import { PadrillosService } from '../../services/padrillos/padrillos.service';
import { IPadrillos, Padrillos } from '../../models/padrillos.model';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-padrillos',
  templateUrl: './padrillos.component.html',
  styleUrls: ['./padrillos.component.css']
})
export class PadrillosComponent implements OnInit {

  padrillo: IPadrillos;
  videos: string[];
  videosPages: number[];

  constructor(
    private padrillosService: PadrillosService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService, 
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    window.scroll(0,0);
    this.padrillo = JSON.parse(this.storage.get('padrillo'));
    console.log(this.padrillo);
    if(this.padrillo.videos != null) {
      this.videos = JSON.parse(this.padrillo.videos);    
      this.videosPages = Array.from(Array(Math.ceil(this.videos.length / 3)).keys());
    }
  }

  getUrlVideo(index: number) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`${this.videos[index]}?rel=0&amp;showinfo=0&amp;modestbranding=1&amp;wmode=transparent`);
  }

  getUrlHeader() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`${this.padrillo.header_url}?rel=0&amp;showinfo=0&amp;modestbranding=1&amp;wmode=transparent`);
  }

  getUrlPedigree(option: number) {
    if(this.padrillo.pedigree != null) {
      window.open(this.padrillo.pedigree, "_blank");
    }

  }

  showPedigree() {
    if(this.padrillo.pedigree != null) {
      return true;
    }
    return false;
  }

}

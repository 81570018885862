import { ModuleWithProviders } from '@angular/compiler/src/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { PotrillosComponent } from './components/potrillos/potrillos.component';
import { PadrillosComponent } from './components/padrillos/padrillos.component';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'home', pathMatch: 'full'
    },
    {
        path: 'home',
        component: HomeComponent
    },
    {
        path: 'potrillos',
        component: PotrillosComponent
    },
    {
        path: 'padrillos',
        component: PadrillosComponent
    },

];

export const Routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);


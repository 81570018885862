import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StorageServiceModule} from 'angular-webstorage-service';
import { FormsModule } from '@angular/forms';
import { Ng2PageScrollModule } from 'ng2-page-scroll';
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
//owl carousel
import { CarouselModule } from 'ngx-owl-carousel-o';

// Rutas del sistema
import { Routing } from './app.routing';

//Componentes
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { PadrillosComponent } from './components/padrillos/padrillos.component';
import { PotrillosComponent } from './components/potrillos/potrillos.component';
import { LoaderComponent } from './components/loader/loader.component';
import { FooterComponent } from './components/footer/footer.component';
import { MenuComponent } from './components/menu/menu.component';

import { TextService } from './services/text/text.service';
import { PotrillosService } from './services/potrillos/potrillos.service';
import { PadrillosService } from './services/padrillos/padrillos.service';
import { ReservarService } from './services/reservar/reservar.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PadrillosComponent,
    PotrillosComponent,
    FooterComponent,
    MenuComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    Routing,
    StorageServiceModule,
    FormsModule,
    Ng2PageScrollModule,
    CarouselModule
  ],
  providers: [PotrillosService, PadrillosService, TextService, ReservarService,
    {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit, Inject } from '@angular/core';
import { IPadrillos } from '../../models/padrillos.model';
import { IPotrillos } from '../../models/potrillos.model';
import { PadrillosService } from '../../services/padrillos/padrillos.service';
import { PotrillosService } from '../../services/potrillos/potrillos.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { TextService } from '../../services/text/text.service';
import { ReservarService } from '../../services/reservar/reservar.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('potrilloState', [
      state('inactive', style({
        visibility: 'hidden',
        opacity: '0',
        transition: 'visibility 0s linear 0.33s, opacity 0.33s' 
      })),
      state('active',   style({
        visibility: 'visible',
        opacity: '1'
      })),
      transition('inactive => active, active => inactive',
       animate('100ms ease-out'))
    ])
  ]
})
export class HomeComponent implements OnInit {
  loadingPotrillos = false;
  loadingPadrillos = false;
  modalHistoria: any;
  preofertaText: any;

  counterShow: number = 1;
  filtro_id: number = 0;
  padrillos: IPadrillos[];
  pagesPadrillos: number[];
  formReserva: any = {nombre: '', apellido: '', telefono: '', correo: ''};

  potrillos: IPotrillos[];
  pagesPotrillos: number[];

  days: any;
  hours: any;
  minutes: any;
  seconds: any;
  //countDownDate = new Date("Apr 18, 2018 21:24:00").getTime();
  constructor(
    private router: Router,
    private textService: TextService, 
    private reservarService: ReservarService,
    private padrillosService: PadrillosService,
    private potrillosService: PotrillosService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
  ) { }

  ngOnInit() {
    window.scroll(0,0);
    this.loadPadrillosData();
    this.modalHistoria = this.textService.getHistoriaText();
    this.preofertaText = this.textService.getPreofertaText();

    let potrillos = this.storage.get('potrillos');
    if(potrillos == null || potrillos == undefined) {
      this.loadPotrillosData(true);
    }
    else {
      this.potrillos = JSON.parse(potrillos);
      this.pagesPotrillos = Array.from(Array(Math.ceil(this.potrillos.length / 3)).keys());
    }

    let padrillos = this.storage.get('padrillos');
    if(padrillos == null || padrillos == undefined) {
      this.loadPadrillosData(true);
    }
    else {
      this.padrillos = JSON.parse(padrillos);
      this.pagesPadrillos = Array.from(Array(Math.ceil(this.padrillos.length / 6)).keys());
    }

  
      this.loadPotrillosData();
      this.loadPadrillosData();
 

  }

  customOptions: OwlOptions = {
    loop: true,
    pullDrag: false,
    dots: false,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    navSpeed: 700,
    autoplay:true,
    autoplayTimeout:5000,
    navText: ['', ''],

    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }

  ngAfterViewInit() {  

  }

  private loadPotrillosData(showLoading: Boolean = false) {
    if(showLoading) {
      this.loadingPotrillos = true;
    }
    this.storage.set('potrillos', null);

    this.potrillosService.getAll().subscribe((response) => {
      this.potrillos = response.data;
      this.pagesPotrillos = Array.from(Array(Math.ceil(this.potrillos.length / 3)).keys());
      this.storage.set('potrillos', JSON.stringify(this.potrillos));

      if(showLoading) {
        this.loadingPotrillos = false;
        document.getElementById('defaultBtn').focus();
      }
    }, (error) => {

      if(showLoading) {
        this.loadingPotrillos = false;
      }
    });
  }

  private loadPadrillosData(showLoading: Boolean = false) {
    if(showLoading) {
      this.loadingPadrillos = true;
    }
    this.storage.set('padrillos', null);
    this.padrillosService.getAll().subscribe((response) => {
      this.padrillos = response.data;  
      this.pagesPadrillos = Array.from(Array(Math.ceil(this.padrillos.length / 6)).keys());
      this.storage.set('padrillos', JSON.stringify(this.padrillos));

      if(showLoading) {
        this.loadingPadrillos = false;
      }

    }, (error) => {
      if(showLoading) {
        this.loadingPadrillos = false;
      }
    });
  }

  isShow(ip: number) {
    if(((ip/9) < this.counterShow) || ((this.potrillos[ip].padre_id == this.filtro_id))) {
      return 'block;'
    }
    return 'none;';
  }

  moreProducts() {
    this.counterShow++;
  }

  goToDetailPotrillo(p: IPotrillos) {
    this.storage.set('potrillo', JSON.stringify(p));
    this.router.navigate(['/potrillos']);
  }

  goToDetailPadrillo(p: IPadrillos) {
    this.storage.set('padrillo', JSON.stringify(p));
    this.router.navigate(['/padrillos']);
  }

  getDisplay(p: IPotrillos) {
    if((p.padre_id == this.filtro_id) || (this.filtro_id == 0)) {
      return 'active'
    }
    return 'inactive'
  }

  defaultFiltro() {
    this.filtro_id = 0;
  }
  
  changeFiltro(p: IPadrillos) {
    this.filtro_id = p.id;
  }

  reservar() {
    if(this.formReserva.nombre == '') {
      return false;
    }
    if(this.formReserva.apellido == '') {
      return false;
    }
    if(this.formReserva.telefono == '') {
      return false;
    }
    if(this.formReserva.correo == '') {
      return false;
    }

    this.reservarService.reservar(this.formReserva).subscribe((response) => {
      if(response.msg == 'success') {
        this.formReserva.nombre = '';
        this.formReserva.apellido = '';
        this.formReserva.telefono = '';
        this.formReserva.correo = '';
        alert('Au reserva ha sido enviada.');
      }
    }, (error) => {
      console.log(error);
    });
  }

}

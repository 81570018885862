import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Enviroment } from '../../enviroment';

@Injectable()
export class ReservarService {

  private headers: HttpHeaders = null;

  constructor(public http: HttpClient) { }

  reservar(reserva: any): Observable<any> {
    this.headers = new HttpHeaders({
      'Content-Type':  'application/json'
    })

    const params = new HttpParams()
    .append('nombre', reserva.nombre)
    .append('apellido', reserva.apellido)
    .append('telefono', reserva.telefono)
    .append('correo', reserva.correo)

    return this.http.post(Enviroment.getApiUrl('reservar'), null, {headers: this.headers, params: params});
  }

}
